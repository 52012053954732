@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: 'Nunito';
    src: url('/public/fonts/Nunito.ttf') format('truetype');
}

@font-face {
    font-family: 'Raleway';
    src: url('/public/fonts/Raleway.ttf') format('truetype');
}